export const messages_cs = {
  webName: "Ebizon",
  webNameHint: "Digitální zpěvník s výhodami",

  //dx komponenty
  "dxList-nextButtonText": "Načíst další",
  
  //obecne
  yes: "Ano",
  no: "Ne",
  saved: "Uloženo",
  warning: "Upozornění",
  pageNotFoundTitle: "Stránka nebyla nalezena",
  pageNotFound: "Litujeme, ale požadovaná stránka není k dispozici.",
  recordNotFoundTitle: "Záznam neexistuje",
  recordNotFound: "Požadovaný záznam neexistuje.",
  serverErrorTitle: "Chyba",
  serverError: "Omlouváme se, ale v aplikaci došlo k chybě.",
  unathorizedTitle: "Nepovolený přístup",
  unathorized: "Pro přístup k této stránce nemáte povolení.",
  logout: "Odhlásit",
  storno: "Storno",
  create: "Vytvořit",
  save: "Uložit",
  saveAndStay: "Uložit a zůstat",
  edit: "Upravit",
  editHint: "Upravit tento záznam",
  add: "Přidat",
  addHint: "Přidat nový záznam",
  close: "Zavřít",
  all: "Vše",
  cancel: "Zrušit",
  description: "Popis",
  delete: "Odstranit",
  deleteHint: "Odstranit tento záznam",
  deleted: "Odstraněno",
  confirmDeleteItem: "Opravdu chcete odstranit tento záznam?",
  confirmChangeItem: "Opravdu chcete změnit tento záznam?",
  noData: "Žádná data",
  errorOccurred: "Došlo k chybě",
  refresh: "Obnovit",
  search: "Hledat...",
  addRecord: "Přidat záznam",
  editRecord: "Upravit záznam",
  recordEditation: "Úprava záznamu",
  requiredField: "{0}: toto pole musíte vyplnit",
  maxLengthValidatorMsg: "Maximální délka textu je {0} znaků",
  cancelFilter: "Zrušit filtr",
  dateInsert: "Vytvořeno",
  print: "Tisk",
  printHint: "Otevře verzi pro tisk",
  displayAll: "Zobrazit vše",
  loading: "Načítání...",
  moreActions: "Další akce",
  help: "Nápověda",
  helpHint: "Zobrazit nápovědu k této stránce",
  helpNotFound: "{0}: Nápověda k této stránce neexistuje.",
  showMenu: "Zobrazit menu",
  hideMenu: "Skrýt menu",
  displayPdfSong: "Zobrazení písně ve formátu PDF",
  unsupportedBrowser: "{0}: Tato funkce není ve vašem současném prohlížeči podporována",
  
  menu: "Menu",
  dashboard: "Úvodní stránka",
  player: "Přehrávač",
  songs: "Písně, skladby",
  playlists : "Playlisty",
  bands: "Skupiny",
  messages: "Zprávy",
  user: "Uživatel",
  settings: "Nastavení",
  appVersion: "Verze aplikace",
  myProfileAndSettings: "Můj profil a nastavení",
  
  //profile
  profile: "Profil",
  myProfile: "Můj profil",
  myEbizon: "Můj Ebizon",
  myProfileHint: "Otevřít profil uživatele {0}",
  accessPassword: "Přístupové heslo",
  changePassword: "Změnit heslo",
  userAccount: "Uživatelský účet",
  deleteAccount: "Zrušit účet",
  confirmDeleteAccount: "Chystáte se zrušit svůj uživatelský účet. Přejete si jej opravdu zrušit?",
  deleteAccountSuccess: "Uživatelský účet byl zrušen. Obnovit jej můžete do 365 dní za poplatek dle aktuálního ceníku.",
  invitation: "Pozvánka",
  invitations: "Pozvánky",
  waitingInvitations: "Pozvánky ke schválení",
  waitingInvitationInfo: "Ostatní uživatelé Vám poslali pozvánky. Můžete je schválit, nebo zamítnout.",
  accept: "Schválit",
  decline: "Zamítnout",
  membership: "Členství",
  backups: "Zálohy",
  createBackup: "Zálohovat",
  createBackupHint: "Stáhnout soubor se zálohou",
  confirmBackupAll: "Opravdu chcete stáhnout soubor se zálohou všech písní?",
  confirmBackupSong: "Opravdu chcete stáhnout souboru se zálohou písně {0}?",
  restoreBackup: "Obnovit",
  oldPassword: "Současné heslo",
  newPassword: "Nové heslo",
  changePasswordLine1: "Zde zadejte své současné heslo a požadované nové heslo.",
  restoreFromBackup: "Obnovení ze zálohy",
  restoreFromBackupLine1: "Po náhrání souboru se zálohou se vytvoří všechny písně, které tento soubor obsahuje.",
  restoreBackupSuccess: "Obnova zálohy proběhla úspěšně",
  restoreBackupSuccessLine1: "Počet vytvořených písní: {0}",
  editProfile: "Úprava profilu",
  membershipDemo: "Aplikace Ebizon je nyní ve zkušebním režimu, a to do {0}. Pro její aktivaci zaplaťte roční členství. Děkujeme, že Ebizon zkoušíte.",
  membershipLimited: "Aplikace Ebizon je nyní v omezeném režimu. Pro její aktivaci zaplaťte roční členství. ",
  membershipCanceled: "Uživatelský účet byl zrušen. Pro jeho obnovení zaplaťte roční členství. ",
  membershipPayLink1: "Své roční členství můžete",
  membershipPayLink2: "uhradit zde.",
  backupHint: "Můžete si stáhnout soubor se zálohou všech Vašich skladeb.",
  downloadHint: "Ze souboru se zálohou můžete obnovit Vaše skladby.",
  userInvitationLine1: "Pokud chcete někomu doporučit portál Ebizon, můžete mu odeslat pozvánku k registraci.",
  userInvitationLine2: "Za každou takto zprostředkovanou registraci dostanete slevu na předplatném.",
  userInvitationPlaceholder: "E-mailová adresa",
  userInvitationSuccess: "Pozvánka byla odeslána",

  //profile - membership
  pay: "Zaplatit",
  cantOpenPaymentGateway: "Nepodařilo se otevřít platební bránu",
  totalPrice: "Celkem",
  membershipEnds: "Vaše členství končí {0}",
  noMembership: "Nemáte zaplaceno členství.",
  orderOfMembership: "Objednávka členství",
  orderCommittingToPayment: "Objednávka zavazující k platbě",
  confirmOrderOfMembership: "Opravdu chcete dokončit objednávku členství a přejít k platbě?",
  isInvoiceRequiredPlaceholder: "Vystavit fakturu",
  invoiceDetail: "Fakturační údaje",
  invoiceDetailRequired: "Prosím zadejte fakturační údaje, se kterými má být faktura vystavena.",
  voucher: "Slevový kód",
  useVoucher: "Použít kód",
  orderItem: "Položka",
  price: "Cena",
  invoiceItemType: "",
  invoiceItemTypeSubscription: "Členství",
  invoiceItemTypePenalty: "Přirážka",
  invoiceItemTypeDiscount: "Sleva",
  invoiceItemTypeVoucher: "Voucher",
  invoiceItemTypeAccountReactivation: "Obnovení účtu",
  listOfInvoices: "Přehled objednávek",
  noInvoices: "Zatím nemáte žádnou zaplacenou objednávku.",
  dateIssued: "Datum",
  paymentConfirmation: "Doklad o zaplacení",
  paymentConfirmationHint: "Stáhnout doklad o zaplacení",
  afterPaymentLink1: "Přehled objednávek najdete ve Vašem",
  afterPaymentLink2: "uživatelském profilu",

  //profile - pozvánky
  acceptedInvitations: "Přijaté pozvánky",
  noAcceptedInvitation: "Zatím se žádný uživatel s Vaši pozvánkou nezaregistroval.",
  
  //player
  maximize: "Celá obrazovka",
  maximizeHint: "Zobrazí text písně přes celou obrazovku",
  minimizeHint: "Ukončí režim celé obrazovky",
  playHint: "Spustí automatické posouvání textu",
  stopHint: "Zastaví automatické posouvání textu",
  metronome: "Metronom",
  metronomeHint: "Spustí metronom",
  settingsHint: "Otevře nastavení pro tuto skladbu",
  demo: "Ukázka",
  demoHint: "Otevře odkaz na živou ukázku",
  demoDisabledHint: "Skladba nemá zadán odkaz na živou ukázku",
  transposition: "Transpozice",
  allSongs: "Všechny písně",
  song: "Píseň",
  addSongHint: "Přidá novou píseň",
  resetPlaylistHint: "Zruší filtrování na základě playlistu",
  resetPlaylistDisabledHint: "Není vybrán žádný playlist",
  playlist: "Playlist",
  emptyPlaylist: "Playlist je prázdný",
  selectFromList: "Vyberte ze seznamu",
  offline: "Offline režim",
  confirmOffline: "Opravdu chcete začít stahovat všechny skladby, aby byly dostupné v offline režimu?",
  offlineSuccess: "Všechny písně byly úspěšně uloženy.",
  offlineProgress: "Ukládání skladeb",
  scrolledDown: "Text je posunutý na konec.",
    
  //songs
  mySongs: "Moje písně",
  newSong: "Nová píseň",
  name: "Název",
  interpreter: "Interpret",
  content: "Text písně",
  information: "Informace",
  songContentPlaceholder: "Text písně ve formátu ChordPro, nebo pouze text bez akordů",
  emptyPreview: "Náhled se zobrazí po zadání textu písně.",
  chordproWrongChords: "Text obsahuje neplatné akordy",
  chordproParsingError: "Píseň není možné zobrazit, protože její text obsahuje chyby. Prosím, opravte text písně.",
  editSong: "Úprava písně",
  editPlaying: "Úprava přehrávání",
  tempo:  "Tempo skladby [BPM]",
  duration: "Délka písně",
  rehearseLink: "Odkaz na YouTube, Spotify, atd.",
  scrolling: "Automatické přehrávání",
  font: "Písmo",
  scrollingSpeed: "Úprava rychlosti",
  slowDown: "Pomaleji",
  speedUp: "Rychleji",
  scrollingDelay: "Zpoždění začátku [s]",
  preview: "Náhled",
  songFormat: "Formát",
  formatText: "ChordPro",
  formatPdf: "PDF",
  missingPdf: "Není nahrán PDF soubor s textem písně",
  pdfFile: "PDF soubor",
  songList: "Seznam písní",
  songDetail: "Detail písně",
  openPlayer: "Přehrát",
  openSongPlayerHint: "Otevře písničku v přehrávači",
  openPlaylistPlayerHint: "Otevře playlist v přehrávači",
  insertCommentHint: "Komentář ({0})",
  insertChordHint: "Akord ({0})",
  insertSharpHint: "Křížek",
  insertBackHint: "Zpět ({0})",
  insertVerseBlockHint: "Sloka ({0})",
  insertChorusBlockHint: "Refrén ({0})",
  insertChorusHint: "Opakovat poslední refrén ({0})",
  transposeUp: "Transponovat nahorů",
  transposeDown: "Transponovat dolů",
  chordPro: "ChordPro",
  songsNoData: "Nemáte vytvořeny žádné písně",
  rating: "Oblíbenost",
  usageCount: "Počet přehrání",
  dateLastRecordUsed: "Poslední přehrání",
  onlyOwnerCanEditSong: "Tuto píseň nemůžete upravovat, protože autorem je jiný uživatel.",
  playbackHistory: "Historie přehrávání",
  zeroPlaybackCountPlaylist: "Playlist zatím nebyl přehrán",
  zeroPlaybackCountSong: "Tato píseň zatím nebyla přehrána",
  editSongPagingOption: "Stránkovat",
  editSongPagingOptionHint: "Při zaškrtnutí je zobrazena poute jednu stránka. Mezi stránkami se přepíná tlačítek, klávesových zkratek nebo pohybem prstem na dotykovém zařízení. Když není zaškrtnuto, jsou všechny stránky zobrazeny pod sebou.",
  editSongPagingOptionDisabledHint: "Stránkování není možné měnit u písní, do kterých jsou vepsány poznámky.",
  selectPdfFile: "Vyberte soubor PDF",
  selectOtherPdfFile: "Vyberte jiný soubor PDF",

  //playlists
  addPlaylistHint: "Přidá nový playlist",
  newPlaylist: "Nový playlist",
  playlistNamePlaceholder: "Název playlistu",
  editPlaylist: "Úprava playlistu",
  addSongsToPlaylist: "Přidat do playlistu",
  clone: "Klonovat",
  cloneHint: "Vytvoří kopii tohoto playlistu",
  confirmClonePlaylist: "Opravdu chcete klonovat tento playlist?",
  sharing: "Sdílení",
  addShare: "Přidat uživatele",
  addShareHint: "Nasdílí playlist uživateli nebo skupině",
  users: "Uživatelé",
  sharingWithUsers: "Sdílení s uživateli",
  emptyUserSharing: "Playlist není s nikým sdílen",
  confirmDeleteSharing: "Opravdu chcete přestat sdílet tento playlist s uživatelem {0}?",
  share: "Sdílet",
  message: "Zpráva",
  shareWith: "Sdílet s",
  shareWithEmailPlaceholder: "E-mailová adresa uživatele Ebizon",
  messageForUserPlaceholder: "Zpráva pro uživatele",
  messageForBandPlaceholder: "Zpráva pro skupinu",
  bandSelectorPlaceholder: "Vyberte skupinu",
  defaultSharingMessage: "Uživatel {0} chce s Vámi sdílet playlist",
  notMemberOfBand: "Nejste členem žádné skupiny",
  shareWithIsRequired: "Není vybráno, s kým se chcete playlist sdílet",
  myPlaylists: "Moje playlisty",
  playlistList: "Seznam playlistů",
  playlistDetail: "Detail playlistu",
  playlistShare: "Sdílení playlistu",
  author: "Autor",
  waitingForPlaylistAcceptation: "Pozvánka ke sdílení playlistu byla odeslána. Čeká se na její potvrzení.",
  waitingPlaylistShare: "Máte pozvánku na sdílení playlistu {0}.",
  sharingSuccess: "Přijetí pozvánky ke sdílení playlistu proběhlo úspěšně.",
  sharingSuccessLine1: "Nyní máte přístup k playlistu {0}.",
  sharingSuccessLine2: "Můžete se podívat na",
  sharingSuccessLine3: "písně v playlistu",
  sharingFailure: "Přijetí pozvánky ke sdílení playlistu se nepodařilo.",
  confirmDeclineSharing: "Opravdu chcete zamítnout pozvánku?",
  songsInPlaylist: "Písně v playlistu",
  confirmRemoveSongFromPlaylist: "Opravdu chcete odebrat z playlistu píseň {0}?",
  playlistInvitaion: "Pozvánka ke sdílení playlistu",
  onlyOwnerCanEditPlaylist: "Tento playlist nemůžete upravovat, protože autorem je jiný uživatel.",
  
  //bands
  band: "Skupina",
  myBand: "Moje skupina",
  myBands: "Moje skupiny",
  newBand: "Nová skupina",
  addBandHint: "Přidá novou skupinu",
  bandNamePlaceholder: "Název skupiny",
  editBand: "Úprava skupiny",
  inviteMember: "Přidat člena",
  inviteMemberHint: "Pošle na email pozvánku do skupiny",
  members: "Členové",
  noMembersInBand: "Skupina nemá členy",
  noPlaylistsInBand: "Skupina nemá playlisty",
  leader: "Vedoucí",
  member: "Člen",
  bandList: "Seznam skupin",
  bandDetail: "Detail skupiny",
  bandInvitation: "Pozvánka do skupiny {0}",
  bandInvitationShort: "Pozvánka do skupiny",
  newMember: "Nový člen",
  sendInvitation: "Odeslat pozvánku",
  email: "E-mail",
  inviteEmailPlaceholder: "E-mailová adresa nového člena skupiny",
  setLeader: "Předat vedení",
  confirmSetLeader: "Opravdu chcete předat vedení uživateli {0}?",
  confirmDeleteMember: "Opravdu chcete uživatele {0} odebrat ze skupiny?",
  acceptationSuccess: "Přijetí pozvánky proběhlo úspěšně.",
  acceptationSuccessLine1: "Nyní patříte mezi členy skupiny {0}.",
  acceptationSuccessLine2: "Můžete se podívat na",
  acceptationSuccessLine3: "stránku skupiny",
  acceptationFailure: "Přijetí pozvánky se nepodařilo.",
  confirmDeleteInvitation: "{0}: Opravdu chcete zamítnout tuto pozvánku?",
  confirmAcceptInvitation: "{0}: Opravdu chcete schválit tuto pozvánku?",
  invitationDecline: "Zamítnutí pozvánky",
  invitationAccept: "Schválení pozvánky",

  //login
  loginTitle: "Přihlaste se do svého účtu",
  loginSubtitle: "Vítejte zpět! Prosím, zadejte své přihlašovací údaje.",
  loginLine1: "Ještě nemáte účet?",
  loginLine2: "Zaregistrujte se zdarma!",
  loginLine3: "Zapomenuté heslo?",
  loginDescriptionLine1: "Přidejte se k více než tisícovce uživatelů Ebizon a začněte využívat funkcí digitálního zpěvníku. Digitální zpěvník Ebizon Vám pomůže pamatovat si veškeré texty, organizovat aktuální playlisty v kapele, nebo hrát na vystoupení beze strachu, že zapomenete text, akordy nebo noty.",
  loginDescriptionLine2: "Vyzkoušejte si nezávazně funkce Ebizon po dobu 40 dnů.",
  loginBtn: "Přihlásit se",
  password: "Heslo",
  generalTermsAndConditions: "Všeobecné obchodní podmínky",
  loginError: "Přihlášení se nezdařilo",
  invalidCredentials: "Neplatné uživatelské jméno nebo heslo",
  
  //zapomenuté heslo
  forgottenPasswordTitle: "Resetujte své heslo",
  forgottenPasswordSubtitle: "Zapomněli jste heslo? Zadejte prosím svoji e-mailovou adresu. Na tuto adresu obdržíte odkaz pro vytvoření nového hesla.",
  forgottenPasswordLine1:  "Vzpomněli jste si na heslo?",
  forgottenPasswordLine2:  "Přihlaste se zde.",
  forgottenPasswordDescriptionLine1: "Obsluha digitálního zpěvníku Ebizon je intuitivní a snadná. K dispozici je 40-ti denní testovací lhůta, po kterou si každý může Ebizon bezplatně vyzkoušet.",
  forgottenPasswordDescriptionLine2: "Přiveďte své kamarády a známé do rodiny Ebizon a využijte partnerskou roční slevu.",
  forgottenPasswordBtn: "Resetovat heslo",
  resetSuccess: "Žádost o obnovení hesla byla úspěšně odeslána.",
  resetSuccessLine1: "Nyní prosím otevřete odkaz, který jsme Vám poslali na zadanou e-mailovou adresu.",

  //obnova hesla
  passwordRecoveryTitle: "Nastavte si nové heslo",
  passwordRecoverySubtitle: "Zadejte nové heslo, se kterým se budete přihlašovat.",
  passwordRecoveryBtn: "Nastavit heslo",
  passwordRecoveryDescriptionLine1: "Obsluha digitálního zpěvníku Ebizon je intuitivní a snadná. K dispozici je 40-ti denní testovací lhůta, po kterou si každý může Ebizon bezplatně vyzkoušet.",
  passwordRecoveryDescriptionLine2: "Přiveďte své kamarády a známé do rodiny Ebizon a využijte partnerskou roční slevu.",
  recoverySuccess: "Nové heslo bylo úspěšně nastaveno!",
  recoveryFailure: "Nastavení nového hesla se nepodařilo.",

  //registrace
  registrationTitle:  "Registrace nového účtu",
  registrationSubtitle:  "Pro registraci do Ebizon vyplňte prosím údaje níže.",
  registrationLine1:  "Již máte svůj účet Ebizon?",
  registrationLine2:  "Přihlaste se zde.",
  registrationDescriptionLine1: "Přidejte se k více než tisícovce uživatelů Ebizon a začněte využívat funkcí digitálního zpěvníku. Digitální zpěvník Ebizon Vám pomůže pamatovat si veškeré texty, organizovat aktuální playlisty v kapele, nebo hrát na vystoupení beze strachu, že zapomenete text, akordy nebo noty.",
  registrationDescriptionLine2: "Vyzkoušejte si nezávazně funkce Ebizon po dobu 40 dnů.",
  registrationBtn: "Zaregistrovat se",
  nickname: "Přezdívka",
  registrationSuccess: "Díky za registraci!",
  registrationSuccessLine1: "Nyní prosím otevřete odkaz, který jsme Vám poslali k ověření zadané e-mailové adresy.",
  registrationConfirm: "Potvrzení registrace",
  activationSuccess: "Ověření e-mailu proběhlo úspěšně. Nyní se můžete přihlásit.",
  activationFailure: "Ověření e-mailu se nepodařilo.",
  invitationCode: "Kód zprostředkovatele",
  
  //chaty
  chat: "Chat",
  writeMessagePlaceholder: "Napište zprávu",
  send: "Odeslat",
  addChat: "Přidat uživatele chatu",
  addChatHint: "Zahajte nový chat s uživatelem Ebizon",
  chatsList : "Seznam přidaných uživatelů Ebizon",
  emptyChat: "Chat je zatím prázdný",
  createChat: "Vytvořit chat",
  chatInvitationAccept: "Schválení chatu",
  invitationSuccess: "Přijetí pozvánky k chatu proběhlo úspěšně.",
  invitationSuccessLine1: "Nyní můžete přijímat zprávy od uživatele {0}.",
  invitationSuccessLine2: "Nový chat najdete na stránce",
  invitationSuccessLine3: "zprávy",
  invitationFailure: "Přijetí pozvánky k chatu se nepodařilo.",
  chatInvitation: "Pozvánka k chatu",
  enterEmailExactly: "Musíte zadat celou e-mailovou adresu přesně.",
  messagesBetweenUsers: "Zprávy mezi uživateli Ebizon",

  //jak používat
  howTo: "Jak používat Ebizon",

  //dashboard
  welcomeBack: "Vítejte zpět, {0}",
  welcomeBackDescription: "Vítáme Vás v prostředí Můj Ebizon. Užijte si plně všech funkcí digitálního zpěvníku Ebizon!",
  welcomeBackProfile: "Nastavení účtu",
  membershipActiveTo: "Členství aktivní do",
  membershipNotActive: "Členství není aktivní",
  membershipBtn: "Detaily zde",
  recommendationTitle: "Přiveďte své kamarády",
  recommendationDescription: "Rozšiřte rodinu uživatelů Ebizon o své kamarády a získejte výhody ve formě slevy na ročním členství.",
  recommendationBtn: "Pozvánka k Ebizon",
  subscription: "Předplatné",
  subscriptionEndsIn: "Předplatné končí za",
  subscriptionEnded: "Předplatné skončilo",
  subscriptionBtn: "Prodloužit zde",
  days: "dny",
  days1: "den",
  days2: "dní",
  howToStartTitle: "Nevíte jak začít?",
  howToStartSubtitle: "Kam se vydat?",
  howToStartDescription: "Pokud si ze začátku nevíte rady, klikněte na jeden z obrázků níže. Případně mrkněte na naše video, kde se dozvíte, jak přesně s Ebizon pracovat.",
  howToStartsongsDescription: "Počet písní ve Vašem seznamu je zatím {0}. Písně můžete kategorizovat, editovat ve formátu ChordPro, přehrávat nebo sdružovat v Playlistech.",
  howToStartsongsBtn: "Začněte s písněmi",
  howToStartplaylistsDescription: "Počet playlistů ve Vašem seznamu je zatím {0}. Playlisty můžete editovat, přehrávat nebo sdílet.",
  howToStartplaylistsBtn: "Začněte s playlisty",
  howToStartbandsDescription: "Počet skupin ve Vašem seznamu je zatím {0}. Pracujte se svoji skupinou, sdílejte playlisty.",
  howToStartbandsBtn: "Začněte se skupinami",
  howToStartmessagesDescription: "Ve schránce máte {0} nepřečtených zpráv. Komunikujte se členy Ebizon.",
  howToStartmessagesBtn: "Začněte se zprávami",
  howToUseTitle: "Nevíte jak používat?",
  howToUseSubtitle: "Nevíte, jak s Ebizon pracovat?",
  howToUseDescription: "Podívejte se na naše video, jak pracovat s Ebizon a využijte všechny funkce naplno.",
  statsTitle: "Statistiky",
  statsSubtitle: "Podívejte se na základní statistiky",
  statsDescription: "Podívejte se na statistiky, jak využíváte jednotlivé funkce Ebizon.",
  enterSearchTerm: "Vyhledávání v Ebizon",
  statsChartTitle: "Nejčastěji hrané písně v roce {0}",
  continueToDashboard: "Pokračovat na úvodní stránku",
  lastPlayerSongTitle: "Poslední píseň",
  lastPlayerSongContent: "Naposledy jste přehráli píseň",
  lastPlayerContentBlank: "Zatím jste nepřehráli žádnou píseň",

  // Editace PDF
  drawPdfPageTitle: "Poznámky",
  editPdfPageNoPdf: "Tato píseň nemá PDF",
  editPdfLabel: "Poznámky",
  editPdfLabelHint: "Vepsat poznámky",
  editPdfLabelHintScrolling: "Při zapnutém posouvání textu není možné vepisovat poznámky",
  editPdfBtnSaveCanvas: "Uložit",
  editPdfBtnSaveCanvasHint: "Uložit poznámky",
  editPdfBtnDraw: "Poznámka",
  editPdfBtnDrawHint: "Zapnout/Vypnout psaní poznámek",
  editPdfBtnErase: "Guma",
  editPdfBtnEraseHint: "Zapnout/Vypnout gumu",
  editPdfBtnColorSelect: "Barva",
  editPdfBtnColorSelectHit: "Vybrat barvu pera",
  editPdfBtnStepBack: "Zpět",
  editPdfBtnStepBackHint: "Vrátit se o krok zpět",
  editPdfBtnResetCanvas: "Vymazat",
  editPdfBtnResetCanvasHint: "Vymazat všechny poznámky",
  editPdfBtnCloseHint: "Zavřít poznámky",
  editPdfResetCanvasConfirmTitle: "Vymazat poznámky",
  editPdfResetCanvasConfirmText: "Jste si jisti, že chcete vymazat všechny poznámky?",
  editPdfLeaveChangesAlertTitle: "Neuložené změny",
  editPdfLeaveChangesAlertBody: "Chcete pokračovat bez uložení změn?",
  editPdfBtnColorBlack: "Černá",
  editPdfBtnColorBlue: "Modrá",
  editPdfBtnColorRed: "Červená",

  ////////////
  posledni: ""
};
