import LoadPanel from 'devextreme-react/load-panel';

const LoadingPanel = (props) => {
  return (
    <LoadPanel 
      shadingColor="rgba(255,255,255,0)"
      // delay={100}
      message={props.message ?? ""}
      showPane={true}
      {...props}
    />
  );
}

export default LoadingPanel;
