import { convertStringToDateTime } from "../lib/common";
import { convertPersonFromOData } from "./person";

let model = {
    Id: "",
    IdOriginal: "",
    UserName: "",
    Name: "",
    Person: null,
    IsBandLeader: false,
    UserPaymentStatus: "",
    DemoTo: "",
    IsHelpPageDisplayed: false
};

export const convertUserFromOData = function(data) {
    let self = {};

    if(data.Id) {
        self.Id = data.Id;
    }
    if(data.IdOriginal) {
        self.IdOriginal = data.IdOriginal;
    }
    if(data.Name) {
        self.Name = data.Name;
    }
    if(data.UserName) {
        self.UserName = data.UserName;
    }
    if(data.Person) {
        self.Person = convertPersonFromOData(data.Person);
    }
    if(data.IsBandLeader) {
        self.IsBandLeader = data.IsBandLeader;
    }
    if(data.UserPaymentStatus) {
        // Demo = 0,
        // PaidOnTime = 1,
        // MissingPayment = 2,
        // Blocked = 3,
        // Admin = 4,
        self.UserPaymentStatus = data.UserPaymentStatus;
    }
    if(data.DemoTo) {
        self.DemoTo = convertStringToDateTime(data.DemoTo);
    }
    if(data.UserInfo) {
        self.IsHelpPageDisplayed = data.UserInfo.IsHelpPageDisplayed
    }

    return {
        ...model,
        ...self
    };
}

export default model;
