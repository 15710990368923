import { t } from '../../localization/i18n';
import Popup from 'devextreme-react/popup';

export default function OfflinePopup( { progress }) {
  return (
      <Popup
        title={t("offlineProgress")}
        visible={progress < 100 ? true : false}
        height={"auto"}
        animation={null}
      >
        <div className="progress">
          <div className="progress-bar " role="progressbar" style={{width: `${progress}%`}} aria-valuenow={progress} aria-valuemin="0" aria-valuemax="100"></div>
        </div>
      </Popup>
  );
}
