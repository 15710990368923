import { lazy } from "react";
import LayoutBlank from "../components/layout/LayoutBlank";

const NotFoundPage = lazy(() => import("../pages/NotFound"));
const Login = lazy(() => import("../pages/login/Login"));
const Registration = lazy(() => import("../pages/login/Registration"));
const Activation = lazy(() => import("../pages/login/Activation"));
const ResetPassword = lazy(() => import("../pages/login/ResetPassword"));
const NewPassword = lazy(() => import("../pages/login/NewPassword"));
const ChangePassword = lazy(() => import("../pages/login/ChangePassword"));
const Logout = lazy(() => import("../pages/login/Logout"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const HowTo = lazy(() => import("../pages/HowTo"));
const Player = lazy(() => import("../pages/player/Player"));
const Playlists = lazy(() => import("../pages/playlists/Playlists"));
const Chats = lazy(() => import("../pages/chats/Chats"));
const Songs = lazy(() => import("../pages/songs/Songs"));
const SongAdd = lazy(() => import("../pages/songs/SongAdd"));
const SongEdit = lazy(() => import("../pages/songs/SongEdit"));
const SongPrint = lazy(() => import("../pages/songs/SongPrint"));
const SongDetail = lazy(() => import("../pages/songs/SongDetail"));
const PlaylistAdd = lazy(() => import("../pages/playlists/PlaylistAdd"));
const PlaylistEdit = lazy(() => import("../pages/playlists/PlaylistEdit"));
const PlaylistDetail = lazy(() => import("../pages/playlists/PlaylistDetail"));
const PlaylistShare = lazy(() => import("../pages/playlists/PlaylistShare"));
const PlaylistPrint = lazy(() => import("../pages/playlists/PlaylistPrint"));
const Bands = lazy(() => import("../pages/bands/Bands"));
const BandAdd = lazy(() => import("../pages/bands/BandAdd"));
const BandEdit = lazy(() => import("../pages/bands/BandEdit"));
const BandDetail = lazy(() => import("../pages/bands/BandDetail"));
const BandInvite = lazy(() => import("../pages/bands/BandInvite"));
const BandInvitationAccept = lazy(() => import("../pages/invitations/BandInvitationAccept"));
const PlaylistInvitationAccept = lazy(() => import("../pages/invitations/PlaylistInvitationAccept"));
const ChatInvitationAccept = lazy(() => import("../pages/invitations/ChatInvitationAccept"));
const Profile = lazy(() => import("../pages/profile/Profile"));
const GoPay = lazy(() => import("../pages/profile/GoPay"));
const ProfileEdit = lazy(() => import("../pages/profile/ProfileEdit"));
const RestoreBackup = lazy(() => import("../pages/profile/RestoreBackup"));

const routes = [
    {
        path: "/:locale/dashboard",
        component: <Dashboard />
    },
    {
        path: "/:locale/how-to",
        component: <HowTo />
    },
    {
        path: "/:locale/how-to/:anchor",
        component: <HowTo />
    },
    {
        path: "/:locale/welcome",
        component: <HowTo code={"welcome"} />
    },
    {
        path: "/:locale/login",
        component: <Login />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //registrační formulář
        path: "/:locale/registration",
        component: <Registration />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //po odeslání registračního formuláře
        path: "/:locale/registration-success",
        component: <Registration isSuccess={true} />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //registrační formulář s vyplněným číslem zprostředkovatele (kód uživatele, který poslal pozvánku k registraci)
        path: "/:locale/registration/invitation/:code",
        component: <Registration />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //hack - dvakrát za sebou "cs". V odatech zůstala omylem špatně složená adresa se dvěma "cs".
        //Aby se nemusela složitě nasazovat nová verze, přidala se sem duplicitní routa.
        //Po nasazení f736478 (jeden commit po v24.5.23) se tot může smazat.
        path: "/:locale/cs/registration/invitation/:code",
        component: <Registration />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //odkaz z emailové notifikace Aktivace uživatele
        path: "/:locale/registration/:code",
        component: <Activation />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //po úspěšné aktivaci odkazem
        path: "/:locale/activation-success",
        component: <Activation isSuccess={true} />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //formulář pro změnu hesla
        path: "/:locale/reset-password",
        component: <ResetPassword />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //po úspěšném odeslání požadavku na změnu hesla
        path: "/:locale/reset-password-success",
        component: <ResetPassword isSuccess={true} />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //formulář pro zadání nového hesla
        //odkaz z emailové notifikace Zapomenuté heslo
        path: "/:locale/password-recovery/:code",
        component: <NewPassword />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        //po úspěšném nastavení nového hesla
        path: "/:locale/password-recovery-success",
        component: <NewPassword isSuccess={true} />,
        isPrivate: false,
        layout: LayoutBlank
    },
    {
        path: "/:locale/logout",
        component: <Logout />,
        isPrivate: false
    },
    {
        path: "/:locale/player",
        component: <Player />,
        layout: LayoutBlank
    },
    {
        path: "/:locale/player/song/:songId",
        component: <Player />,
        layout: LayoutBlank
    },
    {
        path: "/:locale/player/playlist/:playlistId/order/:order",
        component: <Player />,
        layout: LayoutBlank
    },
    {
        path: "/:locale/player/playlist/:playlistId",
        component: <Player />,
        layout: LayoutBlank
    },
    {
        path: "/:locale/songs",
        component: <Songs />
    },
    {
        path: "/:locale/song/add",
        component: <SongAdd />
    },
    {
        path: "/:locale/song/edit/:id",
        component: <SongEdit />
    },
    {
        path: "/:locale/song/print/:id",
        component: <SongPrint />,
        layout: LayoutBlank
    },
    {
        //routa musí být až poslední ze všech "song", aby názvy akcí (add, edit) měly přednost před parametrem id
        path: "/:locale/song/:id",
        component: <SongDetail />
    },
    {
        path: "/:locale/playlists",
        component: <Playlists />
    },
    {
        path: "/:locale/playlist/add",
        component: <PlaylistAdd />
    },
    {
        path: "/:locale/playlist/edit/:id",
        component: <PlaylistEdit />
    },
    {
        path: "/:locale/playlist/share/:id",
        component: <PlaylistShare />
    },
    {
        path: "/:locale/playlist/print/:id",
        component: <PlaylistPrint />,
        layout: LayoutBlank
    },
    {
        //routa musí být až poslední ze všech "playlist", aby názvy akcí (add, edit) měly přednost před parametrem id
        path: "/:locale/playlist/:id",
        component: <PlaylistDetail />
    },
    {
        path: "/:locale/bands",
        component: <Bands />
    },
    {
        path: "/:locale/band/add",
        component: <BandAdd />
    },
    {
        path: "/:locale/band/invite/:id",
        component: <BandInvite />
    },
    {
        path: "/:locale/band/edit/:id",
        component: <BandEdit />
    },
    {
        //routa musí být až poslední ze všech "band", aby názvy akcí (add, edit) měly přednost před parametrem id
        path: "/:locale/band/:id",
        component: <BandDetail />
    },
    {
        //odkaz z emailové notifikace Pozvánka do kapely
        path: "/:locale/invitation/band/:code",
        component: <BandInvitationAccept />
    },
    {
        //odkaz z emailové notifikace Sdílení playlistu
        path: "/:locale/invitation/playlist/:code",
        component: <PlaylistInvitationAccept />
    },
    {
        path: "/:locale/invitation/chat/:code",
        component: <ChatInvitationAccept />
    },
    {
        path: "/:locale/chats",
        component: <Chats />
    },
    {
        path: "/:locale/chats/band/:bandId",
        component: <Chats />
    },
    {
        path: "/:locale/profile",
        component: <Profile />
    },
    {
        path: "/:locale/gopay-payment",
        component: <GoPay />
    },
    {
        path: "/:locale/profile/edit",
        component: <ProfileEdit />
    },
    {
        path: "/:locale/restore-backup",
        component: <RestoreBackup />
    },
    {
        path: "/:locale/change-password",
        component: <ChangePassword />,
    },
    {
        path: "/:locale/*",
        component: <NotFoundPage type="page" />
    },
    {
        path: "/*",
        component: <NotFoundPage type="page" />
    },
  ];
   
  export default routes;
