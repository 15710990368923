import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAjax } from "../../contexts/ajax";
import { t } from "../../localization/i18n";
import BtnOffline from "../player/BtnOffline";
import OfflinePopup from "../player/OfflinePopup";
import BtnHelp from "../UI/BtnHelp";
import SidebarItem from "./SidebarItem";
import { useLocation } from "react-router-dom";
import { useAuth } from "../../contexts/auth";
import * as constants from "../../lib/constants";

export default function Sidebar( { module, onToggleSidebarStyle, isCompact } ) {
  const { locale } = useParams();
  const { loggedPerson } = useAuth();
  const { ajaxAction } = useAjax();
  const location = useLocation();
  const [offlineProgress, setOfflineProgress] = useState();
  const [counters, setCounters] = useState();

  const toggleSidebarStyle = useCallback(() => {
    onToggleSidebarStyle(!isCompact);
  }, [onToggleSidebarStyle, isCompact]);

  const loadData = useCallback(async () => {
    let result = await ajaxAction("Users", null, `GetEntityCounter()`);
    setCounters(result);
  }, [ajaxAction]);

  useEffect(() => {
    //jinak by na logonu (případně jiných stránkách, kde není uživatel přihlášený)
    //skončil dotaz chybou Neplatné uživatelské jméno nebo heslo
    if(loggedPerson) {
      loadData();
    }
  }, [location, loadData, loggedPerson]);

  // function toggleSidebarStyle(e) {
  //   props.onToggleSidebarStyle(!props.isSidebarCompact);
  // }

  //progress bar pro offline režim
  const handleProgressChange = useCallback((progress) => {
    setOfflineProgress(progress);
  }, []);

  let items = [
    <SidebarItem to={`/${locale}/dashboard`} title={t("dashboard")} icon={"fa-home"} key="dashboard" />
    ,<SidebarItem to={`/${locale}/how-to`} title={t("howTo")} icon={"fa-circle-info"} key="howTo" />

    ,<SidebarItem to={`/${locale}/songs`} title={t("songs")} icon={"fa-music"} badge={counters?.SongCounter.toString()} css="mt-3" key="songs" isActive={module === constants.MODULE_SONGS} />
    ,<SidebarItem to={`/${locale}/playlists`} title={t("playlists")} icon={"fa-list-music"} badge={counters?.PlaylistCounter.toString()} key="playlists" />
    ,<SidebarItem to={`/${locale}/bands`} title={t("bands")} icon={"fa-users"} badge={counters?.BandCounter.toString()} key="bands" />
    ,<SidebarItem to={`/${locale}/chats`} title={t("messages")} icon={"fa-messages"} badge={counters?.MessageCounter.toString()} key="messages" />

    ,<SidebarItem to={`/${locale}/profile`} title={t("myProfileAndSettings")} icon={"fa-user"} css="mt-3" key="myProfile" />
    ,<SidebarItem to={`/${locale}/logout`} title={t("logout")} icon={"fa-right-from-bracket"} key="logout" />

    ,<BtnHelp locale={locale} key="help" />
    ,<BtnOffline onProgressChange={handleProgressChange} key="offline"/>
  ];

  return (
<nav id="sidebarMenu" className="col-md-3 col-lg-2 d-md-block sidebar collapse pe-2">
  <div className="position-sticky sidebar-sticky">
    {/* tlačítko pro skrytí menu zobrazit jen na velkých obrazovkách */}
    <button
      onClick={toggleSidebarStyle}
      id="btnToggleSidebar"
      className="nav-link d-flex ms-auto d-none d-md-block"
      title={t(isCompact ? "showMenu" : "hideMenu")}
    >
      <i className={`mx-3 mt-1 mb-3 fa-fw fa-regular ${ isCompact ? "fa-chevrons-right" : "fa-chevrons-left"} `} />
    </button>

    {!isCompact &&
      <h4 className="sidebar-heading px-3 mb-3">{t("myEbizon")} <small>{process.env.REACT_APP_VERSION}</small></h4>
    }

    {/* menu pro velké obrazovky */}
    <ul className="nav flex-column mb-2 d-none d-md-block">
      {items}
    </ul>

    {/* menu pro malé obrazovky - po kliku na položku se menu sbalí */}
    <ul className="nav flex-column mb-2 d-block d-md-none">
      {items.map(item => React.cloneElement(item, { isCollapsible: true} ))}
    </ul>
  </div>
  <OfflinePopup progress={offlineProgress} />
</nav>
  );
};
