import React, { useState, useEffect, createContext, useContext, useCallback } from 'react';
import { handleServerResponse } from '../lib/common';
import { getODataContext, webApiUrl, url } from '../lib/odata';
import { t } from "../localization/i18n";
import { convertPersonFromOData } from '../model/person';

const AuthContext = createContext();

function AuthProvider({children}) {
  const AUTHORIZATION_KEY = "authorization"; //autorizační hlavička (token)
  //isAuthInProgress: výchozí stav musí být "true", AppRoute pak nepřesměruje na login, ale ukáže loading
  //využití: přihlášený uživatel, otevřená private stránka, refresh - aplikace neskočí na login, ale čeka na výsledek přihlášení
  const [isAuthInProgress, setIsAuthInProgress] = useState(true);
  const [loggedPerson, setLoggedPerson] = useState(null);
  const [authorization, setAuthorization] = useState(localStorage.getItem(AUTHORIZATION_KEY));
  const [OData, setOData] = useState(null);

  //Na základě jména a hesla získat token
  const getTokenByUsername = useCallback(async (username, password) => {
    //console.log("getTokenByUsername", username);

    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "Username": username,
        "Password": password
      })
    };

    try {
      const response = await fetch(webApiUrl + "security/Logon", options);
      return await processActionResponse(response);
    } catch (error) {
      return await processActionResponse(null);
    }
  }, []);

  //Na základě tokenu získat jemu odpovídající uživatelské jméno
  const getUsernameByToken = useCallback(async (authHeader) => {
    //console.log("getUsernameByToken", authHeader);

    const settings = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": authHeader
      }
    };

    try {
      const response = await fetch(webApiUrl + "security/GetCurrentUser", settings);
      return await processActionResponse(response);
    } catch (error) {
      return await processActionResponse(null);
    }
  }, []);

  //na základě uživatelského jména získat informace o osobě
  const getPersonInfo = useCallback(async (authHeader) => {
    const settings = {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Authorization": authHeader
      }
    };

    try {
      let params = "?$select=Id,NickName&$expand=User($expand=ELVAC.Hadyna.EBIZON.Persistent.Security.User/UserInfo($select=IsHelpPageDisplayed);$select=Id,UserName,ELVAC.Hadyna.EBIZON.Persistent.Security.User/UserPaymentStatus,ELVAC.Hadyna.EBIZON.Persistent.Security.User/DemoTo)";
      const response = await fetch(`${url}Users/GetPersonForCurrentUser${params}`, settings);
      return await processActionResponse(response);
    } catch (error) {
      return await processActionResponse(null);
    }
  }, []);

  //Obsluha odhlášení
  const signOut = useCallback(async () => {
    setAuthorization(null); //musí být null, s prázdnými závorkami se token nevymaže
    setLoggedPerson(null);
    setOData(null);
    setIsAuthInProgress(false);
  }, []);

  //Obsluha přihlášení jménem a heslem
  const signIn = useCallback(async (username, password) => {
    //console.log("signIn", username);

    setIsAuthInProgress(true);
    let resultOfTokenAction = await getTokenByUsername(username, password);
  
    if(resultOfTokenAction.isOk) {
      //token byl v pořádku vygenerován
      let auth = `Bearer ${resultOfTokenAction.data?.token}`;
      setAuthorization(auth);
    }
    else {
      //přihlašování skončilo chybou
      await signOut();
    }

    return resultOfTokenAction;
  }, [signOut, getTokenByUsername]);


  //při změně autorizační hlavičky načíst informace o uživateli
  useEffect(() => {
    if(authorization) {
      localStorage.setItem(AUTHORIZATION_KEY, authorization);

      //vytvoření OData kontextu
      let odata = getODataContext(authorization);
      setOData(odata);

      //načtení informací o přihlášeném uživateli
      getUsernameByToken(authorization).then((result) => {
        if(result.isOk) {
          getPersonInfo(authorization).then((result) => {
            if(result.isOk) {
              let person = convertPersonFromOData(result.data);
              setLoggedPerson(person);
            }
            else {
              signOut();
            }
          });
        }
        else {
          signOut();
        }
      });
    }
    else {
      localStorage.removeItem(AUTHORIZATION_KEY);
      signOut();
    }
  }, [authorization, getUsernameByToken, getPersonInfo, signOut]);

  //při změně "loggedPerson" nastavit příznak, že už přihlašování skončilo
  useEffect(() => {
    if(loggedPerson) {
      setIsAuthInProgress(false);
    }
  }, [loggedPerson]);

  //zpracování odpovědi na ajaxový požadavek
  const processActionResponse = async (response) => {
    let result = {
      isOk: false,
      data: "",
      msg: ""
    }

    try {
      let data = await response.json();
      if(response.ok) {
        result.data = data;
      }
      else {
        result.msg = handleServerResponse(data);
      }
      result.isOk = response.ok;
    }
    catch(r) {
      result.msg = t("serverError");
    }

    return result;
  }

  const reloadPersonInfo = useCallback(async (username, password) => {
    var result = getPersonInfo(authorization).then((result) => {
      if(result.isOk) {
        let person = convertPersonFromOData(result.data);
        setLoggedPerson(person);
      }
    });
    return result;
  }, [authorization, getPersonInfo]);

  return (
    <AuthContext.Provider value={{ loggedPerson, signIn, signOut, authorization, OData, isAuthInProgress, reloadPersonInfo }}>
       {children}
    </AuthContext.Provider>
  );
}

function useAuth() {
  const context = useContext(AuthContext)
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context
}

export { AuthProvider, useAuth }
