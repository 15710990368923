import React, { useState, useEffect, useRef, useCallback } from 'react';
import { errorNotify } from '../../components/UI/Dialog';
import { t } from '../../localization/i18n';

const DEFAULT_DURATION = 0;
const FRAME_RATE = 60; // cílový počet snímků za sekundu
const KEY_SCROLL_SPEED = 5; // rychlost scrollování pomocí kláves (px za frame)
const TIME_CORRECTION_FACTOR = 2; // konstanta pro korekci času
const MANUAL_SCROLL_TIMEOUT = 100; // za jak dlouho (ms) po manuálním zásahu do automatického scrollování se má scrollování opět spustit

export const SmoothScrollContext = React.createContext({scrollPosition: 0});

export default function SmoothScroll({ children, songId, isPlaying, onReachEnd, duration, isScrollingDisabled }) {
  const [scrollPosition, setScrollPosition] = useState(0);
  const contentRef = useRef(null);
  const containerRef = useRef(null);
  const animationRef = useRef(null);
  const startTimeRef = useRef(null);
  const startPositionRef = useRef(0);
  const touchStartY = useRef(null);
  const lastTouchY = useRef(null);
  const isDraggingRef = useRef(false);
  const keyScrollDirectionRef = useRef(0);
  const scrollSpeedRef = useRef(0);
  const totalScrollDistanceRef = useRef(0);
  const resizeObserverRef = useRef(null);
  const initializeScrollRef = useRef(null);
  const prevIsPlayingRef = useRef(isPlaying);
  const manualScrollTimeoutRef = useRef(null);
  const lastManualScrollTimeRef = useRef(0);

  const safeDuration = typeof duration === 'number' && duration > 0 ? duration : DEFAULT_DURATION;

  // Funkce pro získání maximální hodnoty scrollu
  const getMaxScroll = useCallback(() => {
    if (containerRef.current && contentRef.current) {
      return Math.max(0, contentRef.current.scrollHeight - containerRef.current.clientHeight);
    }
    return 0;
  }, []);

  // Funkce pro kontrolu, zda je scroll na konci
  const checkScrollEnd = useCallback(() => {
    const maxScroll = getMaxScroll();
    if (Math.abs(scrollPosition - maxScroll) < 1) {
      errorNotify(t("scrolledDown"));
    }
  }, [getMaxScroll, scrollPosition]);

  // Funkce pro aktualizaci pozice scrollu v bezpečném rozsahu
  const updateScrollPosition = useCallback((newPosition) => {
    const maxScroll = getMaxScroll();
    return Math.max(0, Math.min(newPosition, maxScroll));
  }, [getMaxScroll]);

  // Funkce pro aktualizaci rychlosti scrollování
  const updateScrollSpeed = useCallback(() => {
    const maxScroll = getMaxScroll();
    totalScrollDistanceRef.current = maxScroll;
    scrollSpeedRef.current = safeDuration > 0 ? (maxScroll / safeDuration) * TIME_CORRECTION_FACTOR : 0;
  }, [getMaxScroll, safeDuration]);

  // Funkce pro inicializaci scrollování při změně písně
  const initializeScroll = useCallback(() => {
    updateScrollSpeed();
    setScrollPosition(0);
    startTimeRef.current = null;
    startPositionRef.current = 0;
    if (animationRef.current) {
      cancelAnimationFrame(animationRef.current);
    }
  }, [updateScrollSpeed]);

  // Aktualizujeme referenci na initializeScroll
  useEffect(() => {
    initializeScrollRef.current = initializeScroll;
  }, [initializeScroll]);

  // Funkce pro aktualizaci při změně duration
  const updateOnDurationChange = useCallback(() => {
    updateScrollSpeed();
    startTimeRef.current = null;
    startPositionRef.current = scrollPosition;
  }, [updateScrollSpeed, scrollPosition]);

  // Hlavní animační funkce pro automatické scrollování
  const animate = useCallback((timestamp) => {
    if (!startTimeRef.current) {
      startTimeRef.current = timestamp;
      startPositionRef.current = scrollPosition;
    }
    const elapsedTime = timestamp - startTimeRef.current;
    
    const isAutoScrollAllowed = isPlaying // Přehrávání je aktivní
      && !isDraggingRef.current // Uživatel aktivně nescrolluje (myší, šipkami)
      && Date.now() - lastManualScrollTimeRef.current > MANUAL_SCROLL_TIMEOUT; // Uplynul dostatečný čas od posledního manuálního scrollu
  
    if (isAutoScrollAllowed) {
      const newPosition = startPositionRef.current + scrollSpeedRef.current * elapsedTime;
      if (newPosition >= totalScrollDistanceRef.current) {
        setScrollPosition(totalScrollDistanceRef.current);
        onReachEnd();
        return; // Zastavení animace při dosažení konce
      } else {
        setScrollPosition(newPosition);
      }
    }
  
    animationRef.current = requestAnimationFrame(animate);
  }, [isPlaying, onReachEnd, scrollPosition]);

  // Effect pro inicializaci scrollu při změně songId
  useEffect(() => {
    if (initializeScrollRef.current) {
      initializeScrollRef.current();
    }
  }, [songId]);

  // Effect pro aktualizaci při změně duration
  useEffect(() => {
    updateOnDurationChange();
  }, [safeDuration, updateOnDurationChange]);

  // Effect pro spuštění/zastavení animace při změně stavu přehrávání
  useEffect(() => {
    if (!prevIsPlayingRef.current && isPlaying) {
      checkScrollEnd();
    }
    prevIsPlayingRef.current = isPlaying;

    if (isPlaying) {
      startTimeRef.current = null;
      animationRef.current = requestAnimationFrame(animate);
    } else {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    }
    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
      if (manualScrollTimeoutRef.current) {
        clearTimeout(manualScrollTimeoutRef.current);
      }
    };
  }, [animate, isPlaying, checkScrollEnd]);

  // Effect pro sledování změn velikosti obsahu
  useEffect(() => {
    if (contentRef.current) {
      resizeObserverRef.current = new ResizeObserver(() => {
        updateScrollSpeed();
        if (isPlaying) {
          startTimeRef.current = null;
          startPositionRef.current = scrollPosition;
        }
      });

      resizeObserverRef.current.observe(contentRef.current);

      return () => {
        if (resizeObserverRef.current) {
          resizeObserverRef.current.disconnect();
        }
      };
    }
  }, [updateScrollSpeed, isPlaying, scrollPosition]);

  // Handlery pro dotykové ovládání
  const handleTouchStart = useCallback((e) => {
    touchStartY.current = e.touches[0].clientY;
    lastTouchY.current = e.touches[0].clientY;
    isDraggingRef.current = true;
  }, []);

  const handleTouchMove = useCallback((e) => {
    if(isScrollingDisabled) {
      return;
    }
    e.preventDefault();
    if (!isDraggingRef.current) return;
    const touchY = e.touches[0].clientY;
    const deltaY = lastTouchY.current - touchY;
    setScrollPosition((prev) => updateScrollPosition(prev + deltaY));
    lastTouchY.current = touchY;
    lastManualScrollTimeRef.current = Date.now();
  }, [isScrollingDisabled, updateScrollPosition]);

  const handleTouchEnd = useCallback(() => {
    isDraggingRef.current = false;
    touchStartY.current = null;
    lastTouchY.current = null;
    startTimeRef.current = null;
    startPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  // Handler pro ovládání kolečkem myši
  // V Chrome při aktivním posouvání nefungovalo kolečko myši, proto se nastavuje lastManualScrollTimeRef
  // a na základě toho se pak v "animate" přerušuje animace
  const handleWheel = useCallback((e) => {
    e.preventDefault();
    const newPosition = updateScrollPosition(scrollPosition + e.deltaY);
    setScrollPosition(newPosition);
    startPositionRef.current = newPosition;
    startTimeRef.current = null;
    lastManualScrollTimeRef.current = Date.now();

    // Zrušit předchozí timeout a nastavit nový
    if (manualScrollTimeoutRef.current) {
      clearTimeout(manualScrollTimeoutRef.current);
    }
    manualScrollTimeoutRef.current = setTimeout(() => {
      startTimeRef.current = null;
    }, MANUAL_SCROLL_TIMEOUT);
  }, [updateScrollPosition, scrollPosition]);

  // Handlery pro ovládání klávesnicí
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'ArrowUp') {
      e.preventDefault();
      keyScrollDirectionRef.current = -1;
    } else if (e.key === 'ArrowDown') {
      e.preventDefault();
      keyScrollDirectionRef.current = 1;
    }
  }, []);

  const handleKeyUp = useCallback((e) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      keyScrollDirectionRef.current = 0;
    }
  }, []);

  // Effect pro ovládání scrollování pomocí kláves
  useEffect(() => {
    const handleKeyScroll = () => {
      if (keyScrollDirectionRef.current !== 0) {
        setScrollPosition((prev) => 
          updateScrollPosition(prev + keyScrollDirectionRef.current * KEY_SCROLL_SPEED)
        );
        startTimeRef.current = null;
        startPositionRef.current = scrollPosition;
        lastManualScrollTimeRef.current = Date.now();
      }
    };

    const keyScrollInterval = setInterval(handleKeyScroll, 1000 / FRAME_RATE);

    window.addEventListener('keydown', handleKeyDown, { passive: false });
    window.addEventListener('keyup', handleKeyUp, { passive: false });

    return () => {
      clearInterval(keyScrollInterval);
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyDown, handleKeyUp, updateScrollPosition, scrollPosition]);

  // Effect pro nastavení non-pasivního event listeneru pro touchmove
  useEffect(() => {
    const element = containerRef.current;
    if (element) {
      element.addEventListener('touchmove', handleTouchMove, { passive: false });
      return () => {
        element.removeEventListener('touchmove', handleTouchMove);
      };
    }
  }, [handleTouchMove]);

  return (
    <div 
      ref={containerRef}
      style={{ 
        height: '100%', 
        position: 'relative'
      }}
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onWheel={handleWheel}
    >
      <div 
        ref={contentRef}
        className={"transformation"}
        style={{ 
          transform: `translate3d(0, ${-scrollPosition}px, 0)`,
          // transition: 'transform 0.1s linear',
          // willChange: 'transform'
        }}
      >
        <SmoothScrollContext.Provider value={{ scrollPosition }}>
        {children}
        </SmoothScrollContext.Provider>
      </div>
    </div>
  );
}