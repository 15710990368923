import { NavLink } from 'react-router-dom';

export default  function SidebarItem ( { to, icon, title, css, badge, isCollapsible, isActive}) {
  return (
    // po kliku na isCollapsible položku sbalit menu (použije se jen na malých obrazovkách)
    <li className={`nav-item ${css ?? ""}`}
      data-bs-toggle={ isCollapsible ? "collapse" : ""}
      data-bs-target={ isCollapsible ? "#sidebarMenu" : ""}
    >
      <NavLink to={to} className={`nav-link d-flex align-items-center ${isActive ? "active" : ""}`} title={title}>
        {icon && <i className={`ms-3 fa-fw fa-light ${icon}`} />}
        <span className="ms-3 flex-grow-1">{title}</span>
        {badge && <span className="me-3 badge text-bg-light">{badge}</span>}
      </NavLink>
    </li>
  );
};
