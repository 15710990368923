import { useDxInput } from '../../hooks/useDxInput';
import Autocomplete from 'devextreme-react/autocomplete';
import { useCallback, useEffect, useState } from 'react';
import { t } from '../../localization/i18n';
import { useAjax } from '../../contexts/ajax';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/auth';

export default  function SearchForm ({ height, isMobileSearch, handleSelectedItem }) {
  const { locale } = useParams();
  const { loggedPerson } = useAuth();
  const { ajaxAction } = useAjax();
  let navigate = useNavigate();
  const [dataSource, setDataSource] = useState();
  const dxInput = useDxInput();
  
  // Data načítat jen pokud je uživatel přihlášen.
  // Komponenta je umístěna v Header a dělá to nějakou neplechu, že se aktivuje i bez přihlášení.
  // Dotaz pak padá na tom, že v autorizační hlavičce je null (localstorage je prázdné).
  const loadData = useCallback(async () => {
    if(loggedPerson) {
      let result = await ajaxAction("Users", null, `GetEntitySearcher()`);
      setDataSource(result?.Entities);
    }
  }, [ajaxAction, loggedPerson]);

  //při startu načíst data
  useEffect(() => {
    loadData();
  }, [loadData]);

  const onSelectionChanged = useCallback((e) => {
    if(e.selectedItem) {
      let entity = e.selectedItem.Type.toLowerCase();
      let url = `/${locale}/${entity}/${e.selectedItem.Id}`;

      //TODO zatím neumíme otevírat přímo detail chatu
      if(entity === "chat") {
        url = `/${locale}/chats`;
      }

      if (isMobileSearch) {
        handleSelectedItem();
      }

      navigate(url);
    }
  }, [locale, navigate, isMobileSearch, handleSelectedItem]);

  const renderListItem = (item) => {
    let icon = "";
    let title = t(item.Type.toLowerCase());

    if(item.Type === "Song") {
      icon = "fa-music";
    }
    else if(item.Type === "Playlist") {
      icon = "fa-list-music";
    }
    else if(item.Type === "Band") {
      icon = "fa-users";
    }
    else if(item.Type === "Chat") {
      icon = "fa-envelope";
    }

    return (
      <div className="d-flex align-items-center">
        <i className={`${icon} fa-regular me-3`} title={title}  />
        {item.Name}
      </div>
    );
  }

  return (
    <Autocomplete
      {...dxInput}
      dataSource={dataSource}
      onSelectionChanged={onSelectionChanged}
      valueExpr={"Name"}
      height={height || 60}
      className={"border"}
      placeholder={t("enterSearchTerm")}
      itemRender={renderListItem}
      buttons={[{
        name: "search",
        location: "after",
        options: {
          icon: "fa-regular fa-search",
          type: "default",
          stylingMode: "text",
          height:60,
          width:60
        },
      }]}
    />
  );
}
